import { useNavigate } from "react-router-dom";
import { faEdit, faEye, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Popconfirm, Table, Tag, notification } from "antd";

export default function TableEvaluation(props) {
	const {
		dataSource,
		onChangeTable,
		setToggleModalForm,
		isLoadingChangeStatus,
		handleChangeStatus,
		handleDelete,
		isLoadingDeleteForm,
	} = props;

	const navigate = useNavigate();

	return (
		<Table
			dataSource={dataSource && dataSource.data ? dataSource.data.data : []}
			rowKey={(record) => record.id}
			pagination={false}
			bordered={false}
			onChange={onChangeTable}
			scroll={{ x: "max-content" }}
			className="tbl-main-primary"
		>
			<Table.Column
				title="Action"
				key="action"
				dataIndex="action"
				align="center"
				render={(_, record) => {
					return (
						<>
							<Button
								type="link"
								className="btn-view"
								onClick={() => navigate(`/evaluation/view/${record.id}`)}
							>
								<FontAwesomeIcon icon={faEye} />
							</Button>

							<Button
								type="link"
								className="btn-view btn-edit"
								onClick={() =>
									setToggleModalForm({
										open: true,
										data: record,
									})
								}
							>
								<FontAwesomeIcon icon={faEdit} />
							</Button>

							<Popconfirm
								title="Are you sure to delete this data?"
								onConfirm={() => handleDelete(record)}
								onCancel={() => {
									notification.error({
										message: "Evaluation",
										description: "Data not delete",
									});
								}}
								okText="Yes"
								cancelText="No"
							>
								<Button
									type="link"
									className="btn-delete"
									loading={isLoadingDeleteForm}
									icon={<FontAwesomeIcon icon={faTrash} />}
								/>
							</Popconfirm>
						</>
					);
				}}
			/>
			<Table.Column
				title="Created"
				key="created_at_formatted"
				dataIndex="created_at_formatted"
				sorter={true}
				defaultSortOrder="descend"
			/>
			<Table.Column
				title="Form Name"
				key="form_name"
				dataIndex="form_name"
				sorter={true}
				render={(text, record) => {
					return (
						<Button
							type="link"
							className="p-0 main-primary-color"
							onClick={() => navigate(`/evaluation/view/${record.id}`)}
						>
							{text}
						</Button>
					);
				}}
			/>
			<Table.Column
				title="Year"
				key="school_year"
				dataIndex="school_year"
				sorter={true}
			/>
			<Table.Column
				title="Semester"
				key="semester"
				dataIndex="semester"
				sorter={true}
			/>

			<Table.Column
				title="For Role"
				key="user_role"
				render={(text, record) => {
					let user_role = record.form_user_roles
						.reduce((a, b) => {
							return [...a, b.user_role.role];
						}, [])
						.map((item, index) => <Tag key={index}>{item}</Tag>);
					return user_role;
				}}
			/>
			<Table.Column
				title="Status"
				key="status"
				dataIndex="status"
				sorter={true}
				align="center"
				render={(text, record) => {
					return (
						<Popconfirm
							title={`Are you sure to change the status of this data?`}
							onConfirm={() => handleChangeStatus(record)}
							onCancel={() => {
								notification.error({
									message: "EVALUATION",
									description: "Data status not change",
								});
							}}
							okText="Yes"
							cancelText="No"
						>
							<Checkbox
								className="btn-status"
								checked={record.status === 1 ? true : false}
								disabled={isLoadingChangeStatus}
							>
								{record.status === 1 ? "Visible" : "Not Visible"}
							</Checkbox>
						</Popconfirm>
					);
				}}
			/>
		</Table>
	);
}
