import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPenToSquare,
	faHome,
	faPieChart,
	faMobileAndroid,
} from "@fortawesome/pro-regular-svg-icons";
import { Menu } from "antd";

export const adminHeaderMenuLeft = (
	<>
		{/* <div className="ant-menu-left-icon">
            <Link to="/subscribers/current">
                <span className="anticon">
                    <FontAwesomeIcon icon={faUsers} />
                </span>
                <Typography.Text>Subscribers</Typography.Text>
            </Link>
        </div> */}
	</>
);

export const adminHeaderDropDownMenuLeft = () => {
	const items = [
		// {
		//     key: "/subscribers/current",
		//     icon: <FontAwesomeIcon icon={faUsers} />,
		//     label: <Link to="/subscribers/current">Subscribers</Link>,
		// },
	];

	return <Menu items={items} />;
};

export const adminSideMenu = [
	{
		title: "Dashboard",
		path: "/dashboard",
		icon: <FontAwesomeIcon icon={faHome} />,
		moduleCode: "M-01",
	},
	{
		title: "Evaluation",
		path: "/evaluation",
		icon: <FontAwesomeIcon icon={faPenToSquare} />,
		moduleCode: "M-02",
	},
	{
		title: "Reports",
		path: "/reports",
		icon: <FontAwesomeIcon icon={faPieChart} />,
		moduleCode: "M-03",
	},
	{
		title: "MOBILE APK",
		path: "/mobile-apk",
		icon: <FontAwesomeIcon icon={faMobileAndroid} />,
		moduleCode: "M-03",
	},
];
